import Image from "next/image";
import Fade from "react-reveal/Fade";
import SearchBar from "./Searchbar";
import Link from "next/link";
import EcoSystem from "../../pages/home/ecoSystem";
import RotatingZwalletLogo from "../3d/RotatingZwalletLogo";
import useScreenWidth from "../../hooks/useScreenWidth";

const hero = () => {
  const screenWidth = useScreenWidth();
  return (
    <section className="">
      <div className="relative container pb-12 pt-[116px] md:pt-[98px]  lg:pt-[168px] w-full ">
        <div className="relatve">
          <div className="w-full gap-4 pt-7 md:pt-12 flex flex-col md:flex-row items-center">
            <SearchBar main={true} className="md:w-[80%] !pb-0" />
            <Link
              href="/dashboard?startforfree=true"
              className="md:w-[20%] items-center hidden lg:flex justify-center rounded-md text-[18px] font-medium 
                // Light mode styles
                border border-jacarta-900 text-jacarta-900 hover:bg-jacarta-900 hover:text-white
                // Dark mode styles
                dark:bg-jacarta-800 dark:text-white dark:border-transparent dark:hover:bg-white dark:hover:text-jacarta-900 dark:hover:border-jacarta-900 
                cursor-pointer py-[24px] px-[30px] z-[2] transition-all duration-300"
            >
              Get your .DOMAINZ
            </Link>
          </div>{" "}
          <div className="grid lg:grid-cols-2 gap-4 mt-10 h-full">
            <div className="h-full lg:pr-16 xl:pr-24">
              <Fade ssrReveal bottom>
                <h1 className=" text-jacarta-900 font-light font-display mt-[20px] mb-4 text-start text-[28px] md:text-[32px] dark:text-white  lg:text-[40px] xl:text-[46px] leading-tight ">
                  End-to-End Web3 Ecosystem Platform{" "}
                  <span className="animate-gradient">With No Code</span>
                </h1>
              </Fade>
              <Fade ssrReveal bottom delay={350}>
                <p className="mb-7 text-start text-[16px] md:text-[18px] font-normal  ">
                  Email-generated crypto wallets, blockchain identities, NFTs,
                  AI agents, and tokens—live in 15 minutes with instant revenue.
                </p>
              </Fade>
              <div className="   ">
                <Fade ssrReveal bottom delay={350}>
                  <div className="flex flex-col md:flex-row gap-6 md:gap-8 ">
                    <Link
                      href="/dashboard?startforfree=true"
                      className="min-w-[200px] xl:min-w-[230px] items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[20px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center"
                    >
                      Start for Free
                    </Link>
                    <Link
                      href="/community"
                      className="min-w-[200px] xl:min-w-[230px] items-center hidden lg:flex justify-center rounded-md text-[18px] font-medium dark:bg-jacarta-800  hover:bg-jacarta-base cursor-pointer  py-[22px] px-[20px] z-[2] transition-all duration-500 hover:-translate-y-1 bg-gray-300 text-white dark:hover:bg-white dark:hover:text-gray-300"
                    >
                      Explore Our Tools
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
            <Fade ssrReveal right delay={350}>
              <div className="flex items-center justify-center relative mt-4 lg:mt-0">
                <Image
                  src="/images/Hero-img.svg"
                  width={1175}
                  height={499}
                  className="xl:scale-110"
                />
                <div className="absolute -bottom-12 lg:bottom-3 xl:bottom-0 -right-36 md:-right-32 lg:-right-32 xl:-right-44 z-50">
                
                    <RotatingZwalletLogo scale={ screenWidth < 1024 ? 12 : 19} />
               
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="">{/* <VideoIntro /> */}</div>
      <div className="hidden md:block mt-10">
        {/* <TLD />  */}
        <EcoSystem />
      </div>
      <div
        className={`h-[400px] md:h-[700px] lg:h-[850px] absolute mt-[30%] sm:mt-[20%]  md:mt-[23%] 
            lg:mt-[15rem]
           w-full  z-[-1] top-0  left-0 dark:mix-blend-lighten mix-blend-difference`}
      >
        <div className=" w-full h-full z-[-1] animate-fade">
          <Image
            fill
            sizes="100vw"
            src="/images/hero/hero-space.png"
            alt="hero-background"
            className=" z-[-1] object-cover hidden dark:block"
          />
        </div>
      </div>
    </section>
  );
};

export default hero;
