import React from "react";
import dynamic from 'next/dynamic';
import { Hero } from "../../components/component";
import Divider from "../../components/common/Divider";

// Dynamic imports with loading options
const ContactUs = dynamic(() => import("./contactUs"), {
  loading: () => <div className="loading-spinner">Loading...</div>
});

const Questions = dynamic(() => import("./questions"));
const MajorFeatures = dynamic(() => import("../../components/features/majorFeatures"), {
  loading: () => <div className="loading-spinner">Loading...</div>,
  priority: true,
});
const Pricing = dynamic(() => import("./Pricing"));
const TrustedCompanies = dynamic(() => import("../../components/domains/TrustedCompanies"), {
  ssr: false,
  loading: () => <div className="loading-spinner">Loading...</div>,
});
const Apply = dynamic(() => import("../../components/Community/Apply"));
const Common = dynamic(() => import("./Common"), {
  loading: () => <div className="loading-spinner">Loading...</div>,
  ssr: false,
});
const TLDsld = dynamic(() => import("./TLD"));
const Press = dynamic(() => import("./Press"));

const homeFaq = [
  {
    id: 1,
    question: " What exactly is a Web3 domain?",
    answer:
      " A Web3 domain, also known as a decentralized domain or blockchain domain, is a unique identifier on a blockchain that replaces traditional URLs. It enables users to access decentralized websites, apps, and content without relying on traditional DNS systems.",
  },
  {
    id: 2,
    question: "What are the advantages of using a Web3 domain?",
    answer:
      "Web3 domains offer several benefits, including enhanced security, censorship resistance, and ownership control. They are not controlled by centralized authorities, reducing the risk of domain hijacking or censorship. Additionally, they can simplify cryptocurrency transactions by allowing users to send funds to human-readable addresses.",
  },
  {
    id: 3,
    question: " What are the capabilities of Web3 domains?",
    answer:
      "Web3 domains provide various capabilities, including linking to decentralized websites (dApps), receiving cryptocurrencies directly, and enabling personalized subdomains. They also offer a seamless way to interact with blockchain-based services, NFT marketplaces, and other decentralized applications.",
  },
  {
    id: 4,
    question: "Are there any renewal fees for Web3 domains?",
    answer:
      "Unlike traditional domains, NetZylo Web3 domains don't have renewal fees. Once you've acquired a Web3 domain, you have ongoing ownership without the need to pay annual renewal fees, making them a cost-effective choice.",
  },
];
const menuItems = [
  "NFT Collections",
  "Audience Management",
  "Pricing Plans",
  "Openness Page",
];

const serviceData = {
  "NFT Collections": {
    title: "NFT Collections",
    description: "Explore our unique digital collectibles and NFT marketplace.",
    features: ["Custom NFT Creation", "Marketplace Integration", "Auction System"],
  },
  "Audience Management": {
    title: "Audience Management",
    description: "Powerful tools to manage and engage with your community.",
    features: ["Analytics Dashboard", "User Segmentation", "Engagement Metrics"],
  },
  "Pricing Plans": {
    title: "Pricing Plans",
    description: "Flexible pricing options for businesses of all sizes.",
    features: ["Basic Plan", "Pro Plan", "Enterprise Solutions"],
  },
  "Opensea Page": {
    title: "Opensea Page",
    description: "Transparency and open communication with our community.",
    features: ["Public Roadmap", "Community Feedback", "Regular Updates"],
  },
};

const ZWalletFetaures = [
  "Top-level Domain",
  "AI Agents",
  "Launch a token",
  "Social",
];

const ZWalletServiceData = {
  "Top-level Domain": {
    title: "Top-level Domain",
    description: "Explore our unique digital collectibles and NFT marketplace.",
    features: ["Custom NFT Creation", "Marketplace Integration", "Auction System"],
  },
  "AI Agents": {
    title: "AI Agents",
    description: "Explore our unique digital collectibles and NFT marketplace.",
    features: ["Custom NFT Creation", "Marketplace Integration", "Auction System"],
  },
  "Launch a token": {
    title: "Launch a token",
    description: "Explore our unique digital collectibles and NFT marketplace.",
    features: ["Custom NFT Creation", "Marketplace Integration", "Auction System"],
  },
  "Social": {
    title: "Social",
    description: "Explore our unique digital collectibles and NFT marketplace.",
    features: ["Custom NFT Creation", "Marketplace Integration", "Auction System"],
  },
};

const Home_1 = ({data}) => {

  return (
    <main className=" xl:px-0 overflow-hidden">
      <Hero />
      <Divider />
      <MajorFeatures />
      <Divider className="container" />
      <TLDsld/>
      <Divider className="container" />
      <Common
      id='onboard'
        title="Simple Web3 Onboarding- No Code Required"
        description="Set up a custom onboarding page where users can search, buy, and own Web3 domains in minutes. With email-generated crypto wallets, and full DNS integration, brands can onboard their communities instantly-no developers needed."
        img="/images/onboard.svg"
        menuItems={ZWalletFetaures}
         serviceData={ZWalletServiceData}
      />
      <Divider className="container" />
      <Common
        title="Z Wallet - Your Fully Branded Crypto Wallet"
        description=" More than just a wallet-Z Wallet is where your onboarded users access everything your brand offers. From NFTs, tokens, and Al agents to exclusive drops and future engagements, all in a fully branded, no-code, EVM-compatible wallet that connects to any dApp via WalletConnect."
        img="/images/z-wallet.svg"
        right
        partnerItems
        type='z-wallet'
        zwallet={true}
        menuItems={ZWalletFetaures}
        serviceData={ZWalletServiceData}

      />
      <Divider className="container" />
      <Common
      id='nft-launch'
        title="NFT Launchpad - Engage, Reward, Monetize"
        description="Create custom NFT collections and send them directly to user wallets. Connect NFTs to digital assets, products, or events, with smart contracts stored in your email-generated wallet and showcased on OpenSea. Scalable pricing plans let you start for free and grow as your audience expands."
        img="/images/home3.svg"
        menuItems={ZWalletFetaures}
        serviceData={ZWalletServiceData}
      />
      <Divider className="container" />
      <Common
      id='ai-agents'
        menuItems={menuItems}
        serviceData={serviceData}
        title="Your TLD, AI Agent, and Token-All Connected"
        description="Turn your TLD into a complete Web3 ecosystem by launching an Al agent and token under the same name. Your Al can sell sub-level domains, promote on X, act as a chatbot in Z Wallet, and engage with your community, while your token adds even more value through market cap and sales. This is the future of engagement."
        img="/images/Ai-agent.svg"
        right
        partnerItems
        type='ai-agent'
      />

      <Divider className="container" />
      <Press data={data} />
    
      <Divider className="container" />
      <TrustedCompanies />
      <Divider className="container" /> <Divider className="container" />
      <Pricing home={true} />
      <Divider className="container" />
      <Apply />
      <Divider className="container" />
      <Questions data={homeFaq} />
      <Divider className="container" />
      <ContactUs />
    </main>
  );
};

export default Home_1;
