import { useTheme } from "next-themes";
import Image from "next/image";
import React from "react";
import Marquee from "react-fast-marquee";

const Eco = ({ filterType }) => {
  const partnerItem = [
    {
      img: "openai",
      img_dark: "openai-dark",
      alt: "openai-logo",
    },
    {
      img: "polygon",
      img_dark: "polygon-dark",
      alt: "polygon-logo",
    },
    {
      img: "solana",
      img_dark: "solana-dark",
      alt: "solana-logo",
    },
    {
      img: "quicknode",
      img_dark: "quicknode-dark",
      alt: "quickNode-logo",
    },
    {
      img: "vercel",
      img_dark: "vercel-dark",
      alt: "vercel-logo",
    },

    {
      img: "tangem",
      img_dark: "tangem-dark",
      alt: "tangem-logo",
    },
    {
      img: "thirdWeb",
      img_dark: "thirdWeb-dark",
      alt: "thirdweb-logo",
    },
    // {
    //   img: "metamask",
    //   img_dark: "metamask-dark",
    //   alt: "metamask-logo",
    //   type: ["wallet"],
    // },

    // {
    //   img: "trusted-wallet",
    //   img_dark: "trusted-wallet-dark",
    //   alt: "trusted-wallet-logo",
    //   type: ["wallet"],
    // },
    // {
    //   img: "wallet-connect",
    //   img_dark: "wallet-connect-dark",
    //   alt: "wallet-connect-logo",
    //   type: ["wallet"],
    // },
    // {
    //   img: "paper",
    //   img_dark: "paper-dark",
    //   alt: "paper-logo",
    //   type: ["wallet"],
    // },
    // {
    //   img: "rainbow",
    //   img_dark: "rainbow-dark",
    //   alt: "rainbow-logo",
    //   type: ["wallet"],
    // },
  ];

  const { theme } = useTheme();
  const [items, setItems] = React.useState(partnerItem);

  React.useEffect(() => {
    const isDarkMode = theme === "dark" || theme === "system";

    const filteredItems = partnerItem.filter((item) => {
      // If filterType is not provided, include all items
      if (!filterType) return true;

      // If the item has no type, exclude it
      if (!item.type) return false;

      // Handle both array and single string filterType
      if (Array.isArray(filterType)) {
        // Check if any of the filterTypes are included in the item's type array
        return filterType.some((type) => item.type.includes(type));
      } else {
        // Check if the single filterType is included in the item's type array
        return item.type.includes(filterType);
      }
    });

    // Map to dark mode images if necessary
    const finalItems = isDarkMode
      ? filteredItems.map((item) => ({
          ...item,
          img: item.img_dark || item.img,
        }))
      : filteredItems;

    setItems(finalItems);
  }, [theme, filterType]);

  return (
    <>
      <Marquee className="space-x-4">
        {items.map((item, i) => (
          <div
            className="flex flex-shrink-0 items-center justify-center rounded-2.5xl md:px-6"
            key={i}
          >
            <div className="w-[105px] h-[110px] md:h-[120px] lg:h-[150px] md:w-[100%] flex items-center justify-center">
              <Image
                width={250}
                height={190}
                className="object-cover rounded-[10px]"
                src={`/images/eco/${item.img}.png`}
                alt={item.alt}
              />
            </div>
          </div>
        ))}
      </Marquee>
    </>
  );
};

export default Eco;
