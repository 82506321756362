import { Environment, OrbitControls, useGLTF } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import { useRef, useEffect } from 'react'

// Preload the model
useGLTF.preload('/3d-models/Z-Wallet.glb')

function RotatingZwalletLogo({ scale = 8 }) {
  return (
    <div className="w-full h-full relative">
      <Canvas 
        camera={{ position: [0, 0, 10], fov: 45 }}
        className="bg-transparent absolute top-0 left-0 w-full h-full"
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        {/* <OrbitControls enableZoom={false} /> */}
        <Model scale={scale} />
        <Environment preset='city' />
      </Canvas>
    </div>
  )
}

export default RotatingZwalletLogo

function Model({ scale }) {
  const { scene } = useGLTF('/3d-models/Z-Wallet.glb')
  const modelRef = useRef()

  useEffect(() => {
    // Cleanup function
    return () => {
      if (modelRef.current) {
        modelRef.current = null
      }
    }
  }, [])

  useFrame((state) => {
    if (modelRef.current) {
      modelRef.current.rotation.y -= 0.01
    }
  })

  return (
    <primitive 
      ref={modelRef}
      object={scene.clone()}
      scale={scale}
      position={[0, 0, 0]}
    />
  )
}

